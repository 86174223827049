.search-container {
  background-color: #191414;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif';
}

.search-input {
  width: 90%;
  padding: 8px;
  background-color: #B3B3B3;
  color: #191414;
  border: none;
  border-radius: 20px;
  margin-bottom: 10px;
  font-size: 0.8em;
}

.search-results {
  width: 100%;
  margin-top: 10px;
}

.result-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.result-item {
  cursor: pointer;
  padding: 5px;
  background-color: #191414;
  color: #FFFFFF;
  margin-bottom: 5px;
  border-radius: 4px;
  border: 1px solid #B3B3B3;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  box-sizing: border-box;
}

.result-image {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 2px;
}

.result-text-container {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.result-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.result-name {
  font-weight: bold;
}

.result-artist {
  font-size: 0.8em;
  color: #B3B3B3;
}



@media (max-width: 768px) {
  .search-input {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .result-image {
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }

  .result-item {
    flex-direction: column;
    height: auto;
    padding: 10px;
    text-align: center;
  }

  .result-text-container {
    margin-top: 8px;
  }

  .result-name {
    font-size: 0.9em;
  }

  .result-artist {
    font-size: 0.7em;
  }
}